export default {
  showRequiredIndicator: true,
  trackingParameters: [
    "gad",
    "gclid",
    "fblid",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ],
};
