let rootApiUrl = process.env.VUE_APP_AXIOS_ROOT_URL || "/api/";
let rootSSRApiUrl = process.env.VUE_APP_AXIOS_SSR_ROOT_URL || rootApiUrl;
export default {
  defaultRequestAdapter: "saffronAxios",
  defaultResponseAdapter: "saffron",
  requestAdapters: {
    saffronAxios: {
      rootUrl: rootApiUrl,
      ssrRootUrl: rootSSRApiUrl,
      axiosRetryAttempts: 0,
    },
  },
  responseAdapters: {
    saffron: {},
  },
  cache: {
    cacheGetRequestsByDefault: true,
    defaultCacheTtlMs: 10 * 1000,
    minimalPurgeInterval: 10 * 1000,
    clearCacheOnPost: true,
  }
};
