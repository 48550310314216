import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { getCurrentInstance } from "vue";
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";

export default (props, store, router) => {
    store = store ?? useStore();
    let { asyncOps } = asyncOperations({}, store);

  return {
    hitSourceTracking: async () => {
      let finalRouter = router ?? useRouter();

      if (utilities.isSSR()) {
        return null;
      }

      if (!finalRouter || !finalRouter.currentRoute) {
        return false;
      }

      await finalRouter.isReady();
      let query = finalRouter?.currentRoute?.value?.query;

      if (!query) {
        return false;
      }

      let campaignName = query[config.tracking.sourceQueryKey];

      if (!campaignName) {
        return false;
      }


      await asyncOps.asyncCall(
        `tracking/source/hit/${campaignName}`,
        {},
        { method: "post" }
      );
    },
  };
};
