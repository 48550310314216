export default async ({ asyncOps, store }) => {
  let navFactory;
  let navItems;
  let isVendor = store && !!store.getters["user/profile"]?.Vendor?.id;
  let userItems = {
    profile: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-profile" },
        },
      },
      icon: "artists-user-dashboard-profile",

      label: "userDashboard.nav.profile",
    },
    orders: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-orders" },
        },
      },
      icon: "artists-user-dashboard-orders",

      label: "userDashboard.nav.orders",
    },
    payments: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-payments" },
        },
      },
      icon: "artists-user-dashboard-payments",

      label: "userDashboard.nav.payments",
    },
    reviews: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-reviews" },
        },
      },
      icon: "artists-user-dashboard-reviews",

      label: "userDashboard.nav.reviews",
    },
    notifications: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-notifications" },
        },
      },
      icon: "artists-user-dashboard-notifications",

      label: "userDashboard.nav.notifications",
    },
    conversations: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-conversations" },
        },
      },
      icon: "artists-user-dashboard-conversations",

      label: "userDashboard.nav.conversations",
    },

    contracts: {
      label: "artists.userDashboard.contracts.navLabel",
      icon: "artists-user-dashboard-contracts",

      action: {
        type: "routerNavigation",
        data: {
          to: {
            name: "user-dashboard-contracts",
          },
        },
      },
    },
  };

  let vendorItems = {
    vendorProfile: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-vendor-profile" },
        },
      },
      icon: "artists-user-dashboard-profile",
      label: "userDashboard.nav.vendorProfile",
    },
    vendorOrders: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-vendor-orders" },
        },
      },
      icon: "artists-user-dashboard-orders",
      label: "userDashboard.nav.vendorOrders",
    },
    vendorReviews: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-vendor-reviews" },
        },
      },
      icon: "artists-user-dashboard-reviews",
      label: "userDashboard.nav.vendorReviews",
    },
    notifications: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-notifications" },
        },
      },
      icon: "artists-user-dashboard-notifications",

      label: "userDashboard.nav.notifications",
    },
    conversations: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "user-dashboard-conversations" },
        },
      },
      icon: "artists-user-dashboard-conversations",

      label: "userDashboard.nav.conversations",
    },

    contracts: {
      label: "artists.userDashboard.contracts.navLabel",
      icon: "artists-user-dashboard-contracts",
      action: {
        type: "routerNavigation",
        data: {
          to: {
            name: "user-dashboard-vendor-contracts",
          },
        },
      },
    },
    busyWindows: {
      label: "artists.vendorDashboard.busyWindows.navLabel",
      icon: "artists-user-dashboard-conversations",
      action: {
        type: "routerNavigation",
        data: {
          to: {
            name: "user-dashboard-vendor-busy-windows",
          },
        },
      },
    },
    // reviews, products,vendor profile
  };

  let finalItems = isVendor ? vendorItems : userItems;

  return finalItems;
};
