module.exports = {
  name: "Artists",
  iconPaths: {
    favicon32: "artists/images/favicons/favicon-32x32.png",
    favicon16: "artists/images/favicons/favicon-16x16.png",
    appleTouchIcon: "artists/images/favicons/apple-touch-icon-152x152.png",
    maskIcon: "artists/images/favicons/safari-pinned-tab.svg",
    msTileImage: "artists/images/favicons/msapplication-icon-144x144.png",
  },
  themeColor: "#4d3791",
};
