let debugFunction = (message, level, data) => {
  if (!config.debug) {
    return;
  }

  level = parseInt(level);

  if (typeof console === "undefined") {
    return;
  }

  if (typeof data === "undefined") {
    data = "[data was not provided by caller]";
  }

  let levelColor;
  let wrapperColor = "#f50a41";

  switch (level) {
    case 3:
    case "3":
      levelColor = wrapperColor;
      break;
    case 2:
    case "2":
      levelColor = "#d97409";
      break;
    case 0:
    case "0":
      levelColor = "white";
      break;
    case 1:
    case "1":
    default:
      levelColor = "white";
      break;
  }

  if (level === 0 || level === "0") {
    wrapperColor = "#0c8718";
  }
  console.log(
    "%c/============================================================",
    "color: " + wrapperColor + ";"
  );

  if (level > 0) {
    console.log(
      "%c|%c Error [%cLevel " + level + "%c]:",
      "color: " + wrapperColor + ";",
      "color:white;",
      "color: " + levelColor + ";",
      "color:white;"
    );
  } else {
    console.log(
      "%c|%c Info [%cLevel " + level + "%c]:",
      "color: " + wrapperColor + ";",
      "color:white;",
      "color: " + levelColor + ";",
      "color:white;"
    );
  }

  console.log(
    "%c|%c Description: %c" + message,
    "color: " + wrapperColor + ";",
    "color:white;",
    "color:white;;"
  );
  console.log(
    "%c| %cdata: ",
    "color: " + wrapperColor + ";",
    "color:white; ",
    data
  );
  console.log(
    "%c\\============================================================",
    "color: " + wrapperColor + ";"
  );

  if (level > 2) {
    console.log("Throwing Exception for error with level 3 and above");
    throw new Error(message);
  }
};

let errorFunction = (message, data) => {
  return debugFunction(message, 3, data);
};

let warnFunction = (message, data) => {
  return debugFunction(message, 2, data);
};

let noticeFunction = (message, data) => {
  return debugFunction(message, 1, data);
};

let infoFunction = (message, data) => {
  return debugFunction(message, 0, data);
};
module.exports = {
  debug: debugFunction,
  error: errorFunction,
  warn: warnFunction,
  notice: noticeFunction,
  info: infoFunction,
};
