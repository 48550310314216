export default {
  currency: {
    useConversions: true,
    default: "ILS",
    availableCurrencies: ["ILS"],
  },
  productTypes: {
    activeProductTypes: [
      { value: "default", label: "ecommerce.product.types.default.label" },
      { value: "timedService", label: "ecommerce.product.types.timedService.label" },
      { value: "contractService", label: "ecommerce.product.types.contractService.label" },
    ],
  },
  order: {
    statuses: ["new", "canceled", "payed", "approved", "completed"],
  },
};
