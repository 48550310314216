
export default ({ store }) => {

  return {
    fields: {
      name: {
        name: "name",
        type: "text",
        label: "ecommerce.product.fields.name",
        // placeholder: "ecommerce.product.fields.namePlaceHolder",
        translatable: true,
        validation: {
          required: {},
          string: {},
        },
      },
      information: {
        name: "information",
        type: "editor",
        label: "ecommerce.product.fields.information",
        validation: {
          required: {},
        },
      },
      featured: {
        name: "featured",
        type: "checkbox",
        label: "ecommerce.product.fields.featured",
        validation: {},
      },
      images: {
        name: "images",
        type: "repeatable",
        label: "ecommerce.product.fields.images.label",
        fields: {
          url: {
            name: "url",
            type: "mediaUrl",
            label: "ecommerce.product.fields.images.url",
          },
        },
      },
      introImage: {
        name: "introImage",
        type: "mediaUrl",
        label: "ecommerce.product.fields.introImage",
      },
      price: {
        name: "price",
        type: "price",
        label: "ecommerce.product.fields.price",
        validation: {
          required: {},
        },
      },
      currency: {
        name: "currency",
        type: "currency",
        label: "ecommerce.product.fields.currency",
        validation: {
          required: {},
        },
      },
      additionalInformation: {
        name: "additionalInformation",
        type: "repeatable",
        label: "ecommerce.product.fields.additionalInformation.label",
        repeatable: {
          fullWidth: true,
        },
        translatable: true,
        fields: {
          title: {
            name: "title",
            type: "text",
            label: "ecommerce.product.fields.additionalInformation.title",
          },
          content: {
            name: "content",
            type: "editor",
            label: "ecommerce.product.fields.additionalInformation.content",
          },
        },
      },
      introText :{
        name: "introText",
        type: "textarea",
        label: "artists.product.fields.introText",
        maxCharacters: 60,
        validation: {
          required: {},
          maxLength: {
            params: {
              max: 60,
            },
          },
        },
      },
      serviceDuration : {
        name: "serviceDuration",
        type: "text",
        label: "artists.product.fields.serviceDurationLabel",
        legend: "artists.product.fields.serviceDurationLegend",
        placeholder: "artists.product.fields.serviceDurationPlaceholder",
        translatable: true,
        validation: {
          required: {},
          integer: {},
        },
      },

    },
  };
};
