import { helpers } from "@vuelidate/validators";

export default function (params, b, c) {
  let validator = (value) => {
    if (!value) {
      return false;
    }


    if (Array.isArray(value) && value.length <= params.max) {
      return true;
    }
    if (typeof value === "object" && Object.keys(value).length <= params.max) {
      return true;
    }

    // value is not array, not object, or length is too much
    return false;
  };

  return helpers.withParams({ type: "maxElements" }, validator);
}
