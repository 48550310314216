export default ({ store }) => {
  return {
    fields: {
      name: {
        name: "name",
        type: "text",
        label: "ecommerce.vendor.fields.name",
        // placeholder: "ecommerce.vendor.fields.namePlaceHolder",
        translatable: true,
        isPrimaryField: true,
        validation: {
          required: {},
          string: {},
        },
        filterable: {},
        itemListOptions: {
          show: true,
        },
        transform: {
          public: true,
        },
      },
      languages: {
        name: "languages",
        type: "select2",
        multiple: true,
        label: "artists.vendor.fields.languages",
        translatable: true,
        validation: {
          required: {},
        },
        list: config.languages,
        defaultValue: "",
      },
      heroImageUrl: {
        name: "heroImageUrl",
        type: "mediaUrl",
        label: "ecommerce.vendor.fields.heroImageUrl",
      },
      logoImageUrl: {
        name: "logoImageUrl",
        type: "mediaUrl",
        label: "ecommerce.vendor.fields.logoImageUrl",
      },
      intro: {
        name: "intro",
        type: "textarea",
        label: "artists.vendor.fields.intro",
        translatable: true,
        maxCharacters: 100,
        validation: {
          required: {},
          maxLength: {
            params: {
              max: 100,
            },
          },
        },
      },
      originLocation: {
        name: "originLocation",
        type: "text",
        label: "artists.vendor.fields.originLocation",
        translatable: true,
        maxCharacters: 20,
        validation: {
          required: {},
          maxLength: {
            params: {
              max: 20,
            },
          },
        },
      },
      images: {
        name: "images",
        type: "repeatable",
        label: "artists.vendor.fields.images.label",
        translatable: true,
        fields: {
          image: {
            name: "image",
            type: "mediaUrl",
            label: "artists.vendor.fields.images.imageLabel",
          },
          description: {
            name: "description",
            type: "text",
            label: "artists.vendor.fields.images.descriptionLabel",
          },
        },
        validation: {
          // required: {},
        },
      },
      audio: {
        name: "audio",
        type: "repeatable",
        label: "artists.vendor.fields.audio.label",
        translatable: true,
        fields: {
          audio: {
            name: "audio",
            type: "mediaUrl",
            label: "artists.vendor.fields.audio.audioLabel",
          },
          description: {
            name: "description",
            type: "text",
            label: "artists.vendor.fields.audio.descriptionLabel",
          },
        },
      },
      videos: {
        name: "videos",
        type: "repeatable",
        label: "artists.vendor.fields.videos.label",
        translatable: true,
        fields: {
          youtubeID: {
            name: "youtubeID",
            type: "youtubeVideoId",
            label: "artists.vendor.fields.videos.youtubeIDLabel",
          },
          description: {
            name: "description",
            type: "text",
            label: "artists.vendor.fields.videos.descriptionLabel",
          },
        },
        validation: {
          // required: {},
        },
      },
      generalInfo: {
        name: "generalInfo",
        type: "repeatable",
        label: "artists.vendor.fields.generalInfo.label",
        translatable: true,
        fields: {
          title: {
            name: "title",
            type: "text",
            label: "artists.vendor.fields.generalInfo.titleLabel",
          },
          content: {
            name: "content",
            type: "textarea",
            label: "artists.vendor.fields.generalInfo.contentLabel",
          },
        },
        validation: {
          // required: {},
        },
      },
      GeoLocations: {
        name: "GeoLocations",
        type: "mappedSelect2",
        valueKey: "id",
        translateList: false,
        labelKey: "label",
        label: "artists.vendor.fields.serviceLocations",
        placeholder: false,
        itemComponent: "iconItem",
        asyncListSource: "entity/geoLocation",
        asyncListLive: false,
        asyncDataTextFilterParamName: "name",
        textFilterAsSaffronListFilter: true,
        multiple: true,
        asyncListData: {
          children: true,
          simplified: true,
        },
        validation: {},
      },
    },
  };
};
