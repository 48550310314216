export default {
  fields: {
    name: {
      name: "name",
      type: "text",
      label: "ecommerce.vendor.fields.name",
      validation: {
        required: {},
        string: {},
      },
    },
    tags: {
      name: "Tags",
      type: "mappedSelect2",
      valueKey: "id",
      translateList: false,
      labelKey: "name",
      label: "core.entity.tagsLabel",
      placeholder: false,
      class: "",
      id: "",
      itemComponent: "iconItem",
      asyncListSource: "tag/alias/artist-categories/children",
      asyncListLive: false,
      asyncDataTextFilterParamName: "name",
      textFilterAsSaffronListFilter: true,
      multiple: true,
      asyncListData: {
        children: true,
        simplified: true,
      },
      validation: {},
      filterable: {},
      transform: {
        public: true,
      },
      contexts: {
        listFilter: {
          omit: true,
        },
      },
    },
    about: {
      name: "about",
      type: "editor",
      label: "ecommerce.vendor.fields.about",
      validation: {},
    },
    intro: {
      name: "intro",
      type: "textarea",
      label: "artists.vendor.fields.intro",
      maxCharacters: 100,
      validation: {
        required: {},
        maxLength: {
          params: {
            max: 100,
          },
        },
      },
    },
    languages: {
      name: "languages",
      type: "select2",
      multiple: true,
      label: "artists.vendor.fields.languages",
      validation: {
        required: {},
      },
      list: config.languages,
      defaultValue: "",
    },
    originLocation: {
      name: "originLocation",
      type: "text",
      label: "artists.vendor.fields.originLocation",
      maxCharacters: 20,
      validation: {
        required: {},
        maxLength: {
          params: {
            max: 20,
          },
        },
      },
    },
    images: {
      name: "images",
      type: "repeatable",
      label: "artists.vendor.fields.images.label",
      fields: {
        image: {
          name: "image",
          type: "mediaUrl",
          label: "artists.vendor.fields.images.imageLabel",
        },
        description: {
          name: "description",
          type: "text",
          label: "artists.vendor.fields.images.description",
        },
      },
      validation: {},
    },
    audio: {
      name: "audio",
      type: "repeatable",
      label: "artists.vendor.fields.audio.label",
      fields: {
        audio: {
          name: "audio",
          type: "mediaUrl",
          label: "artists.vendor.fields.audio.audioLabel",
        },
        description: {
          name: "description",
          type: "text",
          label: "artists.vendor.fields.audio.descriptionLabel",
        },
      },
    },
    videos: {
      name: "videos",
      type: "repeatable",
      label: "artists.vendor.fields.videos.label",
      fields: {
        youtubeID: {
          name: "youtubeID",
          type: "text",
          label: "artists.vendor.fields.videos.youtubeIDLabel",
        },
        description: {
          name: "description",
          type: "text",
          label: "artists.vendor.fields.videos.descriptionLabel",
        },
      },
      validation: {},
    },
    generalInfo: {
      name: "generalInfo",
      type: "repeatable",
      label: "artists.vendor.fields.generalInfo.label",
      fields: {
        title: {
          name: "title",
          type: "text",
          label: "artists.vendor.fields.generalInfo.titleLabel",
        },
        content: {
          name: "content",
          type: "textarea",
          label: "artists.vendor.fields.generalInfo.contentLabel",
        },
      },
      validation: {},
    },
  },
};
